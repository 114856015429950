
import { FormValidations } from "@/mixins/form-validations";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { FileCheck } from "@/mixins/file-check";

@Component({})
export default class FilePicker extends mixins(FormValidations, FileCheck) {
  @Prop({ default: false }) showImage!: boolean;
  @Prop({ default: false }) big!: boolean;
  @Prop({ default: "" }) filePreview!: string;
  @Prop({ default: null }) file: File | null = null;
  @Prop({ default: "*", required: false }) type!: string;
  @Prop({ default: false, required: false }) bgwhite!: boolean;
  @Prop({ default: "Rules.imageSelect" }) labelFp!: string;
  @Prop({ default: false }) disabled!: boolean;
  value: File | null = null;
  bgcolor = this.$vuetify.theme.themes.light.primary;

  mounted() {
    this.value = this.file;

    if (this.bgwhite) {
      this.bgcolor = "white";
    }
  }

  @Watch("value")
  changeFile() {
    this.$emit("change", this.value);
  }
}
